import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import dateFormat from "dateformat";
import Fond from "../assets/fond.png";

export default function Home() {
    const [keywords, setKeywords] = useState(dayjs('2022-04-07'));
    const [campain, setCampain] = useState('');
    const [url, setURL] = useState('');

    const handleChangeCampain = (e) => { setCampain(e.target.value); };

    const timestamp =dateFormat(keywords, "yyyy/mm/dd");

    const handleClickImage = () => {
        if (timestamp && campain) {
            setURL('<img src="https://statistiques-web.univ-lemans.fr/matomo.php?idsite=1&rec=1&bots=1&url=https%3A%2F%2Fexample.com%2Femail-opened%2F'+ campain + "/" +timestamp +'&action_name=Email%20opened&_rcn='+ campain +'&_rck='+ timestamp +'" style="border:0;” alt=""/>')
        }
    }

    useEffect(() => {
        if (url) { console.log('yes', url); }
    }, [keywords, campain, url]);

    return(
        <Box sx={{ width: '800px', height: "600px", m: 'auto', backgroundImage: `url(${Fond})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} >
            <Box sx={{ width: '500px', m: ' auto', pt: '50px', pb: '25px' }} >
                <Typography variant="h1" gutterBottom sx={{ fontSize: '3em', textAlign: 'center', fontFamily: "Fredoka One, cursive" }} >
                    Generateur d'URL
                </Typography>

                <FormControl fullWidth sx={{ mt: '25px', pb: '25px' }}>

                    <InputLabel id="demo-simple-select-label">Nom de campagne</InputLabel>
                    <Select value={campain} label="Nom de campagne" onChange={handleChangeCampain} sx={{ width: '100%', m: '15px 0', backgroundColor: "white" }} >
                        <MenuItem value={'Immanquables'}>Les immanquables</MenuItem>
                        <MenuItem value={'CampusEtMoi'}>Campus & moi</MenuItem>
                    </Select>
                    <Stack sx={{marginBottom: '15px', backgroundColor: "white" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker label="Date" value={keywords} minDate={dayjs('2017-01-01')}
                                onChange={(newValue) => { setKeywords(newValue); }}
                                renderInput={(params) => <TextField {...params} />}
                                sx={{ width: '100%', margin: '15px 0' }}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Button variant="contained" fullWidth onClick={() => handleClickImage()} sx={{ p: '15px' }} >
                        Valider
                    </Button>
                </FormControl>
                {url &&
                    <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom sx={{ width: "345px", p: '25px', mb: '0', wordBreak: 'break-all', border: "solid 1px black", borderRadius: "5px", backgroundColor: "white" }} >
                            {url}
                        </Typography>
                        <Button variant="contained" onClick={() => navigator.clipboard.writeText(url)} sx={{ width: "90px", height: '90px', borderRadius: '50%', backgroundColor: '#e91e63' }}>
                            Copier
                        </Button>
                    </Box>
                }
            </Box>
        </Box>
    )
}